import { getDomain, mq } from '@/utils'
import { css, Global } from '@emotion/react'
import { useLocation } from '@reach/router'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { getFontFacesCss } from './font'

const domain = getDomain()

console.log(
  css(
    mq({
      color: ['red', 'green', 'blue', 'darkorchid'],
    }),
  ),
)

export const GlobalCSS = () => {
  const location = useLocation()
  const [language, setLanguage] = useState('en')
  const lang = location.pathname.split('/')[1]
  useEffect(() => {
    const _lang = /^ar|^en|^zh|^es-MX|^tr|^it|^pl/.test(lang) ? lang : 'en'
    setLanguage(_lang)
    if (lang === 'ar') {
      Cookies.set('EXPRESS_LANG', 'ar_SA', { domain: domain, path: '/' })
    } else if (lang === 'zh') {
      Cookies.set('EXPRESS_LANG', 'zh_CN', { domain: domain, path: '/' })
    } else if (lang === 'es-MX') {
      Cookies.set('EXPRESS_LANG', 'es_MX', { domain: domain, path: '/' })
    } else if (lang === 'tr') {
      Cookies.set('EXPRESS_LANG', 'tr_TR', { domain: domain, path: '/' })
    } else if (lang === 'pt') {
      Cookies.set('EXPRESS_LANG', 'pt_BR', { domain: domain, path: '/' })
    } else if (lang === 'it') {
      Cookies.set('EXPRESS_LANG', 'it_IT', { domain: domain, path: '/' })
    } else if (lang === 'pl') {
      Cookies.set('EXPRESS_LANG', 'pl_PL', { domain: domain, path: '/' })
    } else {
      Cookies.set('EXPRESS_LANG', 'en_US', { domain: domain, path: '/' })
    }
  })

  return (
    <Global
      styles={css`
        ${getFontFacesCss('Sarabun', 'Sarabun')}

        :root {
          ${mq({
            '--section-spacing-y': ['20px', '60px', '60px', '80px'],
            '--page-width': 'min(1440px, 100vw)',
            '--content-width': 'min(1280px, 100vw)',
            '--page-px': ['16px', '40px', '40px', '60px', '80px'], // 宽度 < 1440 时的左右间距
            '--fix-page-px': ['0px', '0px', '0px', '0px', '-60px'], // 修复＞ 1440 时的左右间距
            // '--fix-page-px': 'min(-60px, 1440px - 100vw)', // 修复＞ 1440 时的左右间距
            '--page-content-px':
              'calc(50vw - var(--page-width) / 2 + var(--page-px) + var(--fix-page-px))',
            '--slogan-tit-font-size': ['24px', '28px', '32px', '36px', '40px'],
            '--slogan-desc-font-size': ['14px', '16px', '20px', '24px', '28px'],
          })};
        }

        /* 墨西哥国家-机器人iframe */
        #launcher {
          right: 30px !important;
          bottom: 30px !important;
          border-radius: 50% !important;
        }

        * {
          box-sizing: border-box;
        }
        html {
          direction: ${language === 'ar' ? 'rtl' : 'ltr'};
          overflow-x: hidden;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          word-wrap: break-word;
          font-kerning: normal;
          a:hover {
            color: #fff303;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin-top: 0;
            margin-bottom: 0;
            color: unset;
          }
          img {
            max-width: 100%;
            object-fit: cover;
            position: relative;
          }
          .img {
            max-width: 100%;
            object-fit: cover;
            position: relative;
          }
        }

        @font-face {
          font-display: swap;
          font-family: GE-SS;
          font-weight: 400;
          src: url('/font-ar/GE-SS-Light.otf') format('truetype');
        }
        @font-face {
          font-display: swap;
          font-family: GE-SS;
          font-weight: 500;
          src: url('/font-ar/GE-SS-Medium.otf') format('truetype');
        }
        @font-face {
          font-display: swap;
          font-family: GE-SS;
          font-weight: 600;
          src: url('/font-ar/GE-SS-Bold.otf') format('truetype');
        }
        @font-face {
          font-display: swap;
          font-family: GE-SS;
          font-weight: 700;
          src: url('/font-ar/GE-SS-Bold.otf') format('truetype');
        }

        html[lang='en'] body {
          font-family: 'Sarabun', serif, -apple-system, Helvetica, Arial, sans-serif !important;
        }

        html[lang='ar'] body {
          font-family: GE-SS !important;
        }

        body {
          color: hsla(0, 0%, 0%, 0.8);
          margin: 0;
          // cursor: url('/images/dot.ico'), default;
          padding: 0;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
          text-rendering: optimizeLegibility;
          overflow-x: hidden;
        }
        .ant-message {
          z-index: 999999;
        }
        .ant-drawer {
          z-index: 9999999999999999 !important;
        }
        .forbidrootscroll {
          height: 100%;
          overflow: hidden;
        }
      `}
    />
  )
}
