import Cookies from 'js-cookie'
import React, { useCallback, useState } from 'react'
import { logEvent } from '@/utils/logEvent'

export const isBrowser = () => typeof window !== 'undefined'
export const getDomain = () => {
  let domain = '.52imile.cn'
  const hostname = isBrowser() ? window.location.hostname : ''
  if (hostname.includes('localhost') || hostname.includes('192.168.')) {
    domain = hostname
  }
  if (hostname.includes('.imile.com')) {
    domain = '.imile.com'
  }
  return domain
}
export const goToDelivery = () => {
  if (/uat-/.test(window.location.hostname)) {
    window.open('https://uat-express.imile.com')
  } else if (/lpt-/.test(window.location.hostname)) {
    window.open('https://lpt-express.52imile.cn')
  } else if (window.location.hostname.includes('.imile.com')) {
    window.open('https://express.imile.com')
    logEvent('点击_首页_登录')
  } else {
    window.open('https://test-express.52imile.cn')
  }
}

export const goToDeliveryRegister = () => {
  if (/uat-/.test(window.location.hostname)) {
    window.open('https://uat-express.imile.com/#/register')
  } else if (/lpt-/.test(window.location.hostname)) {
    window.open('https://lpt-express.52imile.cn/#/register')
  } else if (window.location.hostname.includes('.imile.com')) {
    window.open('https://express.imile.com/#/register')
    logEvent('点击_首页_注册')
  } else {
    window.open('https://test-express.52imile.cn/#/register')
  }
}

export const logout = () => {
  Cookies.remove('EXPRESS_ACCESS_TOKEN', { domain: getDomain(), path: '/' })
  Cookies.remove('USER_INFO', { domain: getDomain(), path: '/' })
  Cookies.remove('EXPRESS_LANG', { domain: getDomain(), path: '/' })
  localStorage.removeItem('allowSubmitOrder') //退出登录时清空 用户是否审核判断(寄件web端)
  window.location.reload()
}

export * from './css'

interface ModaldataProps {
  visible: boolean
  currentItem: Record<string, unknown>
}

export function useModalState() {
  const [data, setData] = useState<ModaldataProps>({
    visible: false,
    currentItem: {},
  })

  const onOpen = useCallback((obj = {}) => {
    setData((prev) => ({
      ...prev,
      visible: true,
      currentItem: obj,
    }))
  }, [])

  const onClose = useCallback(() => {
    setData((prev) => ({
      ...prev,
      visible: false,
      currentItem: {},
    }))
  }, [])

  return {
    visible: data.visible,
    currentItem: data.currentItem,
    onOpen,
    onClose,
    setData,
  }
}
